import domMixin from '../dom/dom-mixin';


class CurrentLinkManager extends domMixin() {

	constructor({currentClass = 'current', activeClass = 'active', level1Data = 'level1', level2Data = 'level2'}) {
		super();
		this.currentClass = currentClass;
		this.activeClass = activeClass;
		this.level1Data = level1Data;
		this.level2Data = level2Data;
		this.currentLinks = [];
	}


	init() {
		this.events.on(document, 'history:pagechange', this.onPageChange.bind(this));
		this.update();
	}


	onPageChange(event) {
		this.update(event.detail.url);
	}


	update(url = null) {
		if (url === null) {
			url = location.href;
		}
		
		for(const link of this.currentLinks) {
			this.classList(link).remove(this.currentClass);
		}

		if(this.level1Element) {
			this.classList(this.level1Element).remove(this.activeClass);
		}
		this.currentLinks = document.body.querySelectorAll('[href="' + url + '"]');
		for (const link of this.currentLinks) {
			this.classList(link).add(this.currentClass);
			if(this.classList(link.parentElement).contains(this.level2Data)) {
				this.level1Element = link.closest(this.classSelector(this.level1Data));
				this.classList(this.level1Element).add(this.activeClass);
			}
		}
	}

}

export default CurrentLinkManager;
