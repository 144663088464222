import httpRequest from 'superagent';
import PageComponent from '../../common/component/page-component';


class ContactForm extends PageComponent {

	constructor({root, element, busyClass = 'busy', messageAttribute = 'message'}) {
		super({root: root, element: element});
		this.busyClass = busyClass;
		this.messageAttribute = messageAttribute;

		this.busy = false;
	}


	prepare() {
		this.message = this.element.querySelector(this.dataSelector(this.messageAttribute));
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this));
	}


	onSubmit(event) {
		event.preventDefault();

		if (!this.busy) {
			this.toggleBusy();
			const data = this.getFormData();
			const json = JSON.stringify(data);
			httpRequest
				.post(this.element.action)
				.attach('fileToUpload', data['fileToUpload'])
				.field('data', json)
				.set('X-Requested-With', 'XMLHttpRequest')
				.set('Accept', 'application/json')
				.end((error, response) => {
					this.toggleBusy();
					if (error || !response.ok) {
						console.error('response error', error, response);
					} else {
						const body = response.body;
						this.message.textContent = body.message;
					}
				})
			;
		}
	}


	getFormData() {
		const data = {};
			// Loop through each field in the form
		for (const field of this.element.elements) {
			// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
			if (!field.name || field.disabled || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
				continue;
			}

			if ((field.type !== 'checkbox' && field.type !== 'radio' && field.type !== 'file') || field.checked) {
				data[field.name] = field.value;
			}
			if (field.type === 'file') {
				data[field.name] = field.files[0];
			}
		}
		return data;
	}


	toggleBusy() {
		this.busy = !this.busy;
		this.classList().toggle(this.busyClass, this.busy);
	}

}

export default ContactForm;
